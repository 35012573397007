if ($('#events-slider').length) {
	console.log('Load: [components/events.slider.js]');

	var eventsSwiper = new Swiper('#events-slider', {
		// Accessibility
		a11y: true,
		keyboardControl: true,
		mousewheelControl: true,
		// Layout 
		spaceBetween: 30, 
		// slidesPerView: 2,
		freeMode: true,
		setWrapperSize: true,
		breakpoints: {
	    1000: {
	      slidesPerView: 1
	    }, 
	    2000: {
	      slidesPerView: 2
	    }
	  },
		// Transition
		direction: 'horizontal',
		speed: 600, 
		// Loop
		loop: false,
		// Navigation
		touchEventsTarget: 'wrapper',
		grabCursor: true,
		// nextButton: '.swiper-button-next',
		// prevButton: 'swiper-button-prev',
		scrollbar: '.swiper-scrollbar',
		scrollbarHide: false,
		scrollbarDraggable: true,
		// Events
		onInit: function(swiper) {
			console.log('[slider.events.js] events swiper.onInit');
		}
	});
}