/* - - - - - - - - - - - - - - - - - - - - - - - - -
	
	Related files: 
	- path/to/file

  TABLE OF CONTENTS
  
	1.	Gallery grid (Masonry)
	2.	Gallery row (Swiper)
	3.	Individual image (Light gallery - pop up)
	4.	Image with caption (figure)
`
- - - - - - - - - - - - - - - - - - - - - - - - - */

console.log('Load: [multimedia/images.js]');

// 1. Gallery grid - Masonry (photo thumbnails)
// - - - - - - - - - - - - - - - - - - - - - - - - -

if ($('.gallery_grid').length) {
	var $gallery_grid = $('.gallery_grid').masonry({
	  isInitLayout: false,
	  itemSelector: '.grid-item',
	  columnWidth: '.grid-sizer',
	  gutter: 6,
	});

	$('.gallery_grid').imagesLoaded().done(function() {
		console.log('[images] masonry.imagesLoaded');
		$gallery_grid.masonry('layout');
	});
}

// [windowresize.js]
function reloadMasonry() {
	console.log('[images] masonry.reloadItems');
  $gallery_grid.masonry('reloadItems');
}


// 2. Photo gallery row - Swiper slider
// - - - - - - - - - - - - - - - - - - - - - - - - -

if ($('.gallery_row').length) {
	var galleryRow = new Swiper ('.gallery_row', {
		direction: 'horizontal',
		loop: false,
		speed: 600, 
		grabCursor: true,
		centeredSlides: false,
		spaceBetween: 5,
		slidesPerView: 'auto',
		nextButton: '#swiper-next',
		prevButton: '#swiper-prev',
		preloadImages: false,
		lazyLoading: true,
		onInit: function(slide) {
			console.log('[images] gallery_row swiper init');
		}
	});
}


// 3. Individual image (Light gallery - pop up)
// - - - - - - - - - - - - - - - - - - - - - - - - -

if ($('.gallery_grid').length) {
	$('.gallery_grid').lightGallery({
		//height: '80%',
		selector: '.grid-item',
		download: false,
	}); 
}

if ($('.gallery_row').length) {
	// light gallery photo pop-up
	$('.gallery_row').lightGallery({
		selector: '.swiper-slide',
		download: false,
	}); 
}

if ($('.img_enlarge').length || $('img.enlarge').length) {
	$('img.img_enlarge, .img_enlarge img, img.enlarge').each(function() { 
		var img = $(this),
				src = img.attr('src');

		if (img.attr('alt')) var alt = img.attr('alt');
		else var alt = "";

		img.wrap('<div class="img-enlarge" data-src="'+src+'" data-sub-html="'+alt+'"/>');
	});
	$('div.img-enlarge').lightGallery({
		selector: 'this',
		download: false,
		counter: false
	}); 
}


// 4. Image with caption (figure)
// - - - - - - - - - - - - - - - - - - - - - - - - -

if ($('img.caption').length) {
	$('img.caption').each(function() {
		var img = $(this), 
				floats = img.attr('class');

		if (img.attr('alt')) var alt = img.attr('alt');
		else var alt = "";

		// create <figure> element if not exists
		if (!img.parent('figure').length) {
			// get any float classes 
			if (floats.indexOf('float-right') !== -1 || floats.indexOf('float_right') !== -1) 
				var float = 'float-right';
			else if (floats.indexOf('float-left') !== -1 || floats.indexOf('float_left') !== -1)
				var float = 'float-right';
			else var float = '';
			// remove float class on img
			img.removeClass('float-right float_right float-left float_left');

			// wrap image in <figure>
			img.wrap('<figure class="'+float+'"/>');
			// add caption
			img.after('<figcaption>'+alt+'</figcaption>');	
		}
	});
}