// IE fix
if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}
(() => { // IIFE until module system

	const reduceToTallest = (tallest, item) => {
	  const itemHeight = $(item).height();
	  return itemHeight > tallest ? itemHeight : tallest;
	}

	const defaults = {
	  // Accessibility
	  a11y: true,
	  keyboardControl: true,
	  // Layout 
	  spaceBetween: 5, 
	  slidesPerView: 3,
	  slidesPerGroup: 3,
	  breakpoints: {
	    500: {
	      slidesPerView: 1,
	      slidesPerGroup: 1,
	    }, 
	    1000: {
	      slidesPerView: 2,
	      slidesPerGroup: 2,
	    }
	  },
	  // Transition
	  direction: 'horizontal',
	  speed: 600, 
	  // Loop
	  loop: true,
	  // Navigation
	  grabCursor: true,
	  nextButton: '#swiper-next',
	  prevButton: '#swiper-prev',
	  // Load
	  preloadImages: false,
	  lazyLoading: true,
	  // Events
	  onInit(swiper) {
	    // console.time('slider.news.init');
	    console.log('[slider.news.js] news swiper.onInit');
	    // const $newsText = $(swiper.slides, swiper.wrapper).find('.news_text');
	    // const tallestNewsText = $newsText.toArray().reduce(reduceToTallest, 0);
	    // $newsText.height(tallestNewsText);
	  },
		onLazyImageReady() {
	    // console.timeEnd('slider.news.init');
	  },
	};

	class NewsSwiper extends Swiper {
	  constructor(selector, _options = {}) {
	    const options = Object.assign(defaults, _options);
	    super(selector, options);

	    this.originalSlides = this.slides;
	    this.$newsFilterList = $(`.news .filter-list`);
	    this.$newsFilterListItems = this.$newsFilterList.find('li');
	    this.$newsFilters = this.$newsFilterList.find('.news-filter');

	    this.bindHandlers();
	    this.nextButton.on('click', this.unfocusButton);
	    this.prevButton.on('click', this.unfocusButton);
			this.$newsFilters.on('click', this.selectFilter);
	  }

	  unfocusButton(event) {
	    $(event.target).blur();
	  }

	  selectFilter(event) {
	  	event.preventDefault();
	  	const anchor = event.target.hash.substr(1);
			this.params.loop = anchor === 'news_all';
	  	/* this.removeAllSlides();
	  	this.originalSlides.each(index => {
	  		const $slide = $(this.originalSlides[index]);
	  		const display = $slide.hasClass(anchor) && !$slide.hasClass('swiper-slide-duplicate');
	  		if (display) {
	  			this.prependSlide(this.originalSlides[index]);
	  			$slide.addClass('animated zoomIn');
	  		}
		  }); */
	  	this.addActiveClassTo(event.target);
	  }

	  addActiveClassTo(filter) {
			this.$newsFilterListItems.removeClass('active');
			$(filter).parent().addClass('active');
	  }

	  bindHandlers() {
	  	this.unfocusButton = this.unfocusButton.bind(this);
	  	this.selectFilter = this.selectFilter.bind(this);
	  }
	}

	const swiperSelector = '#slider_news';

	if ($(swiperSelector).length) {
	  console.log('Load: [home/slider.news.js]');
	  // console.time('slider.news');
	  const numSlidesDefault = $('.news_1col').length ? 1 : 3;

	  const slidesPerView = numSlidesDefault,
	        slidesPerGroup = numSlidesDefault;

	  const swiperNews = new NewsSwiper(swiperSelector, {
	    slidesPerView,
	    slidesPerGroup,
	  });

	  // console.timeEnd('slider.news');
	}

})();