const animateElements = document.querySelectorAll('.animate-in-view');

// Animate when element is in view
animateElements.forEach(function (el) {
	window.addEventListener('scroll', checkPosition);
	const windowHeight = window.innerHeight;
	function checkPosition() {
		const positionFromTop = el.getBoundingClientRect().top;
		if (positionFromTop - windowHeight <= 0) {
			el.classList.add('animate');
		}
		else {
			el.classList.remove('animate');
		}
	}
});