if ($('#slider_main').length) {
  console.log('Load: [home/slider.home.js]');

  var swiperMain = new Swiper ('#slider_main', {
    // Transition
    // autoHeight: true,
    direction: 'horizontal',
    effect: 'fade',
    speed: 1400, 

    // Autoplay
    autoplay: 10000,
    autoplayDisableOnInteraction: false,

    // Navigation
    grabCursor: true,
    pagination: '.swiper-pagination',
    paginationClickable: true,  
    nextButton: '.swiper-next',
    prevButton: '.swiper-prev',

    // Loop
    loop: true,

    // Load
    preloadImages: false,
    lazyLoading: true,
    lazyLoadingInPrevNext: true,

    // Events
    onInit: function(slide) {
      // console.time('slider.home.init');
      console.log('[slider.home.js] main swiper.onInit');  
    },
    onLazyImageLoad: function(slide, image) {
      // console.log('main onLazyImageLoad');
      // console.log(image);
    },
    onSlideChangeStart: function(slide) {
      // console.log('[slider.home.js] main swiper.onSlideChangeStart');
      // console.log(slide.activeIndex + ' ' + slide.realIndex );
    },
    onLazyImageReady: function(slide, image) {
      // console.log('[slider.home.js] main swiper.onLazyImageReady');
      // console.log(slide.activeIndex + ' ' + slide.realIndex );
      if ($(window).width() < 1000) {
        // set height of each caption to tallest caption
        var boxHeight = 0;
        $('#slider_main .swiper-slide .slider_box').each(function() {
          if ($(this).height() > boxHeight) boxHeight = $(this).height(); 
        });
        $('#slider_main .swiper-slide .slider_box').height(boxHeight); 
      } 
    },
    onAutoPlay: function() {
      // console.timeEnd('slider.home.init');
    }
  });

  $('#slider_main .swiper-controls').on('click', '.swiper-pause', function(event) {  
    // Pause
    if ($(this).hasClass('active')) 
      swiperMain.stopAutoplay();
    // Play
    else  
      swiperMain.startAutoplay();

    $(this).toggleClass('active');
  });
  
}