/**
 * Class for handling "toast"-style notification alerts.
 *
 * Uses global styles for Foundation callout to provide class variations
 * ("primary", etc) and provides positioning logic ("bottom left", etc).
 *
 * The `new ToastNotification()` constructor takes a default configuration,
 * and every subsequent call to `notify()` can override that configuration.
 */
class ToastNotification {
  /**
   * The notification constructor does not create any notifications,
   * but takes options to use as defaults for future notifications.
   *
   * It is responsible for creating a container that will hold all
   * notifications, setting the appropriate classes, and appending it
   * to the body.
   *
   * @param _defaults Notification defaults
   */
  constructor(_defaults = ToastNotification.DEFAULTS) {
    this._defaults = _defaults;
    let notificationContainer = document.querySelector('.notification-container');
    if (!notificationContainer) {
      notificationContainer = document.createElement('div');
      document.body.appendChild(notificationContainer);
    }
    this._notificationContainer = notificationContainer;
    this._setContainerClasses(this._defaults.position);
  }
  /**
   * Creates and deploys a toast notification.
   *
   * Immediately transitions the notification in; if the notification's
   * duration is not set to 0 (infinite), sets a timeout to transition
   * the notification back out automatically.
   *
   * Returns a dismissal function to be called as `[return].dismiss()`.
   *
   * @param message Body content of notification
   * @param options Options to override defaults set at creation
   */
  notify(message, options = {}) {
    const opts = Object.assign(Object.assign({}, this._defaults), options);
    const notification = this._getNotification(message, opts);
    this._setContainerClasses(opts.position);
    this._transitionIn(notification, opts);
    if (opts.duration && opts.duration > 0) {
      setTimeout(() => {
        this._transitionOut(notification, opts);
      }, opts.duration);
    }
    return {
      dismiss: () => this._transitionOut(notification, opts)
    };
  }
  /**
   * Creates a raw notification element, attaches a default "close"
   * listener, and returns the element without attaching it to
   * the DOM – to be done by calling function, `notify()`.
   *
   * @param message Notification body content
   * @param options Options to define toast styles
   */
  _getNotification(message, options) {
    const template = document.createElement('template');
    const className = options.className ? ` ${options.className}` : '';
    const linkHref = options.linkUrl ? ` href="${options.linkUrl}"` : '';
    const outerElement = options.linkUrl ? 'a' : 'div';
    template.innerHTML = `<${outerElement}${linkHref} class="callout notification${className} animated" role="status">
      ${message}
      <button class="close-button" aria-label="Dismiss alert" type="button">
        <span aria-hidden="true">⊗</span>
      </button>
    </${outerElement}>`;
    const notification = template.content.firstChild;
    const closeButton = notification.querySelector('.close-button');
    const closeListener = event => {
      event.preventDefault();
      this._transitionOut(notification, options);
      closeButton.removeEventListener('click', closeListener);
    };
    closeButton.addEventListener('click', closeListener);
    return notification;
  }
  /**
   * Transitions a given notification element in. If the `onClick` option
   * is provided, sets up the click handler, and if the `onNotify` option
   * is provided, calls it.
   *
   * @param notification The notification element to transition
   * @param options Transition options
   */
  _transitionIn(notification, options) {
    options.onClick && notification.addEventListener('click', options.onClick);
    if (options.disableAnimation) {
      if (options.position.top) {
        this._notificationContainer.prepend(notification);
      }
      else {
        this._notificationContainer.appendChild(notification);
      }
      options.onNotify && options.onNotify.apply(notification);
    }
    else {
      if (options.position.left) {
        notification.classList.remove('fadeOutLeft');
        notification.classList.add('fadeInLeft');
      }
      else {
        notification.classList.remove('fadeOutRight');
        notification.classList.add('fadeInRight');
      }
      if (options.position.top) {
        this._notificationContainer.prepend(notification);
      }
      else {
        this._notificationContainer.appendChild(notification);
      }
      const animationEnd = () => {
        options.onNotify && options.onNotify.apply(notification);
        notification.removeEventListener('animationend', animationEnd);
      };
      notification.addEventListener('animationend', animationEnd);
    }
  }
  /**
   * Transitions a given notification element out. If the `onClick` option
   * was provided, removes the click handler, and if the `onDismiss` option
   * was provided, calls it.
   *
   * @param notification The notification element to transition
   * @param options Transition options
   */
  _transitionOut(notification, options) {
    options.onClick && notification.removeEventListener('click', options.onClick);
    if (options.disableAnimation) {
      this._notificationContainer.removeChild(notification);
      options.onDismiss && options.onDismiss.apply(notification);
    }
    else {
      if (options.position.left) {
        notification.classList.remove('fadeInLeft');
        notification.classList.add('fadeOutLeft');
      }
      else if (options.position.right) {
        notification.classList.remove('fadeInRight');
        notification.classList.add('fadeOutRight');
      }
      const animationEnd = () => {
        this._notificationContainer.removeChild(notification);
        options.onDismiss && options.onDismiss.apply(notification);
        notification.removeEventListener('animationend', animationEnd);
      };
      notification.addEventListener('animationend', animationEnd);
    }
  }
  /**
   * Re-initializes the notification container classes given a position.
   *
   * @param position The position object (from options)
   */
  _setContainerClasses(position) {
    const notificationClasses = Object.keys(position).reduce((classes, key) => {
      position[key] && classes.push(`notification-${key}`);
      return classes;
    }, []);
    this._notificationContainer.className = `notification-container ${notificationClasses.join(' ')}`;
  }
}
/**
 * Default options (static property).
 */
ToastNotification.DEFAULTS = {
  position: {
    right: true,
    bottom: true
  }
};
