// Back to top button
// - - - - - - - - - - - - - - - - - - - - - - - - -
((window, document, $) => {
	const $documentElement = $(document.documentElement);
	const $mainContent = $('#main-content');
	const $backToTopButton = $('.footer_sticky .btn-to-top');
	let scrolledPast = false;

	$(window).scroll(onWindowScroll);
	function onWindowScroll() {
		if (!scrolledPast && this.scrollY > 400) {
			scrolledPast = true;
			$backToTopButton.addClass('active');
		} else if (scrolledPast && this.scrollY <= 400) {
			scrolledPast = false;
			$backToTopButton.removeClass('active');
		}
	}

	$backToTopButton.click(backToTop);
	function backToTop() {
		const lastScrollTop = $documentElement.scrollTop();
		$mainContent.attr('tabindex', -1).focus();
		$documentElement.scrollTop(lastScrollTop).animate({ scrollTop: 0 }, 600);
		return false;
	}
})(window, document, jQuery);


/* ((window, document, $) => {
		const anchors = document.querySelectorAll('.in-page-nav a');
		anchors.forEach(function (el) {
			el.addEventListener('click', scrollToElement);
			function scrollToElement(event) {
				const anchoredEl = document.querySelector('#' + this.getAttribute('href').substring(1));
				if (anchoredEl) {
					// anchoredEl.scrollIntoView({ behavior: "smooth"});
					$('html, body').animate({scrollTop: anchoredEl.offsetTop}, 500);
				}
			}
		});
})(window, document, jQuery); */