var resizeTimer, mqTimer;
$(window)
.on('resize', function() { 
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

  	// [navigation/navigation.main.js]
		// alignMainNav();
		if (window.innerWidth > 1000) {
			toggleMobileNav(mainNavBreakpoint);
		}

    // [widgets/sidebar.cta.js]
    sidebarCTA();

  }, 200);
}).on('changed.zf.mediaquery', function(event, name, oldSize) {
  clearTimeout(mqTimer);
  mqTimer = setTimeout(function() {

  	// [navigation/navigation.main.js]
  	// reloadDrilldown();

    // [multimedia/images.js]
    if ($('.gallery_grid').length) {
    	reloadMasonry();
    }

    // If breakpoint is large and up, reinit the tabs
    // if (Foundation.MediaQuery.atLeast('large')) {    
    //   var tabs = new Foundation.Tabs($('[data-tabs]'));
    // }
                     
  }, 200);
});



// console.timeEnd('all scripts');